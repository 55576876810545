import {state, style, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {DialogService} from '@core/services/global/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogComponentData} from '@shared/components/delete-dialog/confirm-dialog-component-data';
import moment from 'moment';
import {SubSink} from 'subsink';
import {environment} from '@env/environment';
import {DeliveryNoteRequest} from '@reporting/designerV2/model/reporting.model.request';
import {ReportingDialogComponent} from '@reporting/designerV2/components/reporting-dialog.component';
import {TicketWorkLogService} from '@ticket/ticket-work-log.service';
import {TicketWorklogModel} from '@ticket/models';
import {ReportService} from '@reporting/report.service';
import {UserSettingsService} from '@core/services/user-settings.service';
import {WorklogTypeService} from '@management/tickets/work-log-types/worklog-type.service';
import {SnackbarService} from '@core/services/snackbar.service';
import {StorageKeyHelper} from '@core/models/storage-key-helper';
import {TicketWorkLogHttpService} from '@ticket/work-log/worklog/ticket-work-log-http.service';
import {WorklogComponentData} from '@ticket/work-log/worklog/worklog-dialog.data.model';
import {TicketWorkLogReportModel} from '@reporting/models';
import {PermissionService} from '@core/services/global/permission.service';
import {filter, switchMap} from 'rxjs/operators';

@Component({
    selector: 'omt-worklog-table',
    templateUrl: './worklog-table.component.html',
    styleUrls: ['./worklog-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'}))
        ])
    ]
})
export class WorklogTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() ticketId: number;
    @Input() workLogs: TicketWorklogModel[] = [];

    get currentUserId(): number {
        return this.authService.currentUser?.id;
    }

    @Output() worklogsLoaded = new EventEmitter<TicketWorklogModel[]>();
    @Output() deletedWorklog = new EventEmitter<TicketWorklogModel>();
    @Output() workLogChanged = new EventEmitter<TicketWorklogModel[]>();

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    private subs = new SubSink();

    dataSource = new MatTableDataSource<TicketWorklogModel>();
    displayedColumns = ['expand', 'date', 'start', 'end', 'endTime', 'duration', 'user', 'actions'];
    showEvaluation = false;

    // Helper variables
    expandedElement = '';
    validWorklogEdit = true;
    isLoading = false;


    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    constructor(
        public dialog: MatDialog,
        private readonly workLogHttpService: TicketWorkLogHttpService,
        private readonly permissionService: PermissionService,
        private readonly workLogService: TicketWorkLogService,
        private readonly workLogTypeService: WorklogTypeService,
        private readonly snackbarService: SnackbarService,
        private readonly translateService: TranslateService,
        private readonly authService: AuthenticationService,
        private readonly dialogService: DialogService,
        private readonly userSettingsService: UserSettingsService,
        private readonly reportService: ReportService) {
    }

    ngOnInit(): void {
        this.subs.sink = this.workLogHttpService.newWorkLog.subscribe((workLog) => {
            this.workLogs = [workLog, ...this.workLogs];
            this.workLogChanged.emit(this.workLogs);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workLogs) {
            this.setWorkLogFormatting();
            this.tableConfiguration();
        }

        if (changes.ticketId) {
            this.load();
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ View
    // -----------------------------------------------------------------------------------------------------

    setPageSize(ev: any): void {
        const pageSize = ev.pageSize;
        this.userSettingsService.setPageSize(StorageKeyHelper.WORK_LOG_PAGE_SIZE_KEY, pageSize);
    }

    getPageSize(): number {
        return this.userSettingsService.getPageSize(StorageKeyHelper.WORK_LOG_PAGE_SIZE_KEY);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Edit WorkLog
    // -----------------------------------------------------------------------------------------------------

    editStartDate(event: string, workLog: TicketWorklogModel): void {
        const splitDate = event.split('.');
        const day = Number(splitDate[0]);
        const month = Number(splitDate[1]) - 1;
        const year = Number(splitDate[2]);

        if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
            this.snackbarService.open(this.translateService.instant('TICKET.INCORRECT_DATE_FORMAT'));
            this.validWorklogEdit = false;
        }

        workLog.start = moment.utc(workLog.start).toDate();
        workLog.start.setFullYear(year);
        workLog.start.setMonth(month);
        workLog.start.setDate(day);

        this.recalculateDuration(workLog);
    }

    editStartTime(event: string, workLog: TicketWorklogModel): void {
        this.workLogService.editStartTime(event, workLog);
    }

    editEndDate(event: string, workLog: TicketWorklogModel): void {
        const splitDate = event.split('.');
        const day = Number(splitDate[0]);
        const month = Number(splitDate[1]) - 1;
        const year = Number(splitDate[2]);

        if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
            this.snackbarService.open(this.translateService.instant('TICKET.INCORRECT_DATE_FORMAT'));
            this.validWorklogEdit = false;
        }

        workLog.end = moment.utc(workLog.end).toDate();
        workLog.end.setFullYear(year);
        workLog.end.setMonth(month);
        workLog.end.setDate(day);

        this.recalculateDuration(workLog);
    }

    editEndTime(event: string, workLog: TicketWorklogModel): void {
        this.workLogService.editEndTime(event, workLog);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Dialog
    // -----------------------------------------------------------------------------------------------------

    openWorkLogDialog(workLog: TicketWorklogModel): void {
        const data = new WorklogComponentData();
        data.worklog = workLog;

        this.dialogService.openWorklogDialog(data);
    }

    async exportDeliveryNote(ticketId: number, worklogId: number): Promise<void> {
        const existingDeliveryNote = await this.checkForExistingDeliveryNote(worklogId);

        if (existingDeliveryNote) {
            this.openReport(existingDeliveryNote.signature?.reportUrl ?? existingDeliveryNote.fileUrl);
            return;
        }

        const req = new DeliveryNoteRequest(null, ticketId, worklogId);
        ReportingDialogComponent.open(this.dialog, req);
    }

    checkForExistingDeliveryNote = (workLogId: number): Promise<TicketWorkLogReportModel> => this.reportService.getExistingDeliveryNote(workLogId).toPromise();

    openReport = (url: string): Window => window.open(environment.apiUrl + url, '_blank');

    /*--------------------------------------------------------------
     # CRUD
     --------------------------------------------------------------*/

    saveEditWorklog(workLog: TicketWorklogModel): void {
        if (!workLog.ticketWorkLogType) {
            this.snackbarService.open(this.translateService.instant('TICKET.TYPE_IS_MISSING'));
            return;
        }

        // Update database
        if (this.validWorklogEdit) {
            this.subs.sink = this.workLogHttpService.updateWorkLog(workLog).subscribe(() => {
                this.workLogChanged.emit(this.workLogs);
            });
        } else {
            this.snackbarService.open(this.translateService.instant('TICKET.INVALID_WORKING_TIME'));
        }
    }

    onDelete(workLog: TicketWorklogModel): void {
        const dialogData = new ConfirmDialogComponentData('Bestätigen', 'Wollen Sie diese Buchung wirklich löschen? Sie kann nicht wiederhergestellt werden.');
        const dialogRef = this.dialogService.openConfirmDialog(dialogData);

        this.subs.sink = dialogRef.componentInstance.deleteClicked
            .pipe(
                filter((clicked) => !!clicked),
                switchMap(() => this.workLogHttpService.delete(workLog.id))
            )
            .subscribe(() => {
                    this.workLogs = [...this.workLogs.filter((x) => x.id !== workLog.id)];
                    this.deletedWorklog.emit(workLog);
                    this.workLogChanged.emit(this.workLogs);
                }
            );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Calculations
    // -----------------------------------------------------------------------------------------------------

    recalculateDuration(workLog: TicketWorklogModel): void {
        const momentDuration = moment.duration(moment.utc(workLog.end).diff(moment.utc(workLog.start)));
        workLog.duration = momentDuration.as('milliseconds');

        if (momentDuration.as('milliseconds') > 0) {
            workLog.durationText = moment.utc(momentDuration.as('milliseconds')).format('HH[h] mm[m]');
        }

        // Working duration > 24h
        if (workLog.duration >= 86400000) {
            const days = Math.round(momentDuration.asDays());
            workLog.durationText = `${days}d ${workLog.durationText}`;
        }

        if (momentDuration.as('milliseconds') < 0) {
            this.snackbarService.open(this.translateService.instant('TICKET.INVALID_DURATION_OF_WORK'));
            this.validWorklogEdit = false;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Load
    // -----------------------------------------------------------------------------------------------------

    load(): void {
        this.loadWorklogs();
    }

    loadWorklogs(): void {
        if (!this.workLogs) {
            this.workLogs = [];
        }

        // Don't load WorkLogs if issue id not available;
        if (!this.ticketId) {
            return;
        }

        this.isLoading = true;
        this.subs.sink = this.workLogHttpService.getByTicketId(this.ticketId).subscribe((data: TicketWorklogModel[]) => {
            this.workLogs = [...data];
            this.setWorkLogFormatting();
            this.isLoading = false;
            this.worklogsLoaded.emit(this.workLogs);
        });
    }

    private setWorkLogFormatting(): void {
        // Convert duration ms in text
        this.workLogs.forEach((x) => {
            // if duration bigger than one day
            if (x.duration > 86400000) {
                x.durationText = moment.utc(x.duration).format('D[d] HH[h] mm[m]');
            } else {
                x.durationText = moment.utc(x.duration).format('HH[h] mm[m]');
            }

            // user has serviceDeskChangeWorklog role, set editable to ture
            if (x.user?.id === this.currentUserId || this.authService.currentUser.userRoles.ticketsArbeitszeitenAendern) {
                x.isEditable = true;
            }
        });
    }

    tableConfiguration(): void {
        this.dataSource.sortingDataAccessor = (item: any, property) => item[property];

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        setTimeout(() => {
            this.dataSource.data = this.workLogs;
        }, 1);
    }

    canViewGeoLocation(worklog: TicketWorklogModel): boolean {
        return this.currentUserId === worklog.createdBy || this.permissionService.hasPermission('controlling');
    }

    canEditWorklog(worklog: TicketWorklogModel): boolean {
        return this.currentUserId === worklog.user?.id || this.permissionService.hasPermission('ticketsArbeitszeitenAendern');
    }
}
