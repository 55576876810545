<div id="worklog-table" class="table-responsive">
  @if (dataSource?.data?.length) {
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef style="width: 4%"></th>
        <td mat-cell *matCellDef="let worklog">
          <i
            class="fa cursor-pointer"
            [ngClass]="{'fa-chevron-right': expandedElement !== worklog, 'fa-chevron-down': expandedElement === worklog}"
            (click)="expandedElement = expandedElement === worklog ? null : worklog"
          ></i>
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef style="width: 7.5%">{{ 'GLOBAL.DATE' | translate }}</th>
        <td mat-cell *matCellDef="let worklog">
          @if (worklog.isEditable) {
            <input class="editable" [ngModel]="worklog.start | germanDateFormat" (blur)="editStartDate($event.target.value, worklog)"/>
          }
          @if (!worklog.isEditable) {
            <span>{{ worklog.start | germanDateFormat }}</span>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef style="width: 7.5%">{{ 'WORK_LOG.START_TIME' | translate }}</th>
        <td mat-cell *matCellDef="let worklog">
          @if (worklog.isEditable) {
            <input type="time" class="editable" [ngModel]="worklog.start | timeFormat" (blur)="editStartTime($event.target.value, worklog)"/>
          }
          @if (!worklog.isEditable) {
            <span>{{ worklog.start | timeFormat }}</span>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef style="width: 7.5%">{{ 'WORK_LOG.END_DATE' | translate }}</th>
        <td mat-cell *matCellDef="let worklog">
          @if (worklog.isEditable) {
            <input class="editable" [ngModel]="worklog.end | germanDateFormat" (blur)="editEndDate($event.target.value, worklog)"/>
          }
          @if (!worklog.isEditable) {
            <span>{{ worklog.end | germanDateFormat }}</span>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="endTime">
        <th mat-header-cell *matHeaderCellDef style="width: 7.5%">{{ 'WORK_LOG.END_TIME' | translate }}</th>
        <td mat-cell *matCellDef="let worklog">
          @if (worklog.isEditable) {
            <input type="time" class="editable" [ngModel]="worklog.end | timeFormat" (blur)="editEndTime($event.target.value, worklog)"/>
          }
          @if (!worklog.isEditable) {
            <span>{{ worklog.end | timeFormat }}</span>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef style="width: 7.5%">{{ 'TICKET.BOOKING_TIME' | translate }}</th>
        <td mat-cell *matCellDef="let worklog">
          {{ worklog.duration | timeFormatFromMs }}
        </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef style="width: 7.5%">{{ 'GLOBAL.USER' | translate }}</th>
        <td mat-cell *matCellDef="let worklog">
          {{ worklog.user.username }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 10%"></th>
        <td mat-cell *matCellDef="let worklog" style="padding-right: 0">
          @if (ticketId) {
            <div class="worklog-actions" fxLayout="row" fxLayoutAlign="flex-end center">
              @if (canViewGeoLocation(worklog) && worklog.longitude && worklog.latitude) {
                <omt-location-icon
                  [location]="{longitude: worklog.longitude, latitude: worklog.latitude}"></omt-location-icon>
              }
              @if (canEditWorklog(worklog)) {
                <button mat-icon-button [matTooltip]="'GLOBAL.SAVE' | translate" (click)="saveEditWorklog(worklog)">
                  <i class="far fa-save cursor-pointer"></i>
                </button>
                <button mat-icon-button [matTooltip]="'GLOBAL.DELETE' | translate" (click)="onDelete(worklog)">
                  <i class="fas fa-trash-alt cursor-pointer"></i>
                </button>
              }
              <button mat-icon-button [matTooltip]="'WORK_LOG.COPY_AS_TEMPLATE' | translate"
                      (click)="openWorkLogDialog(worklog)">
                <i class="fas fa-reply fa-rotate-90 cursor-pointer"></i>
              </button>
              <button mat-icon-button [matTooltip]="'WORK_LOG.CREATE_DISPATCH_NOTE' | translate"
                      (click)="exportDeliveryNote(worklog.ticketId,          worklog.id)">
                <i class="far fa-file-pdf"></i>
              </button>
            </div>
          }
        </td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td class="p-0" mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          @if (element === expandedElement) {
            <div class="p-2" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
              <mat-card class="card mat-elevation-z4 mb-0">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
                      <!--WorklogType dropdown-->
                      <label fxFlex="10" class="mr-2 mb-0">Typ:</label>
                      <div fxFlex="40">
                        @if (!element.isEditable && element.ticketWorkLogType) {
                          <span class="editable">{{ element.ticketWorkLogType.name }}</span>
                        }
                        @if (element.isEditable) {
                          <omt-worklog-type-dropdown [(value)]="element.ticketWorkLogType"></omt-worklog-type-dropdown>
                        }
                      </div>
                      <!--Billable checkbox-->
                      <mat-checkbox [(ngModel)]="element.billable" [disabled]="!element.isEditable">{{ 'WORK_LOG.BILLABLE' | translate }}</mat-checkbox>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <textarea class="worklog-text redesign w-100-p" [placeholder]="'WORK_LOG.SERVICE_TEXT' | translate" [(ngModel)]="element.comment"
                              [disabled]="!element.isEditable"></textarea>
                  </li>
                </ul>
              </mat-card>
            </div>
          }
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{billable: !row.billable}" [class.example-expanded-row]="expandedElement === row"></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
  } @else {
    <div class="bg-white p-10">
      {{ 'WORK_LOG.NO_WORK_LOGS_EXISTING' | translate }}
    </div>
  }

  <!--No Records found-->

  @if (workLogs.length > 0) {
    <section class="m-1" fxLayout="column" fxLayoutAlign="start end">
      <span (click)="showEvaluation = !showEvaluation" class="text-blue cursor-pointer">{{ 'WORK_LOG.SHOW_EVALUATION' | translate }}</span>
      @if (showEvaluation) {
        <omt-work-log-evaluation class="mt-1" [ticketId]="ticketId"></omt-work-log-evaluation>
      }
    </section>
  }

  <mat-paginator (page)="setPageSize($event)" [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="getPageSize()" showFirstLastButtons></mat-paginator>
</div>
