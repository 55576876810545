import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {TicketWorkLogTypeWithWorkLogs} from '@ticket/models';
import {TicketWorkLogHttpService} from '@ticket/work-log/worklog/ticket-work-log-http.service';
import {SubSink} from 'subsink';
import {finalize, map, tap} from 'rxjs/operators';
import {orderBy} from 'lodash';

type WorklogTypeEvaluation = {
    name: string;
    percentage: string;
    totalTime: number;
};

@Component({
    selector: 'omt-work-log-evaluation',
    templateUrl: './work-log-evaluation.component.html',
    styleUrls: ['./work-log-evaluation.component.scss']
})
export class WorkLogEvaluationComponent implements AfterViewInit, OnDestroy {
    @Input() set ticketId(id: number) {
        this._ticketId = id;
        this.getGroupedLogs(id);
    }

    private _ticketId: number;
    private subs = new SubSink();
    private workLogsGrouped: TicketWorkLogTypeWithWorkLogs[];

    totalSum = 0;
    typeEvaluations: WorklogTypeEvaluation[] = [];
    isLoading = false;

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle
    // -----------------------------------------------------------------------------------------------------

    constructor(private readonly workLogHttpService: TicketWorkLogHttpService) {
    }

    ngAfterViewInit(): void {
        this.subs.sink = this.workLogHttpService.newWorkLog.subscribe(() => this.getGroupedLogs(this._ticketId));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ View
    // -----------------------------------------------------------------------------------------------------

    calculatePercentage(log: TicketWorkLogTypeWithWorkLogs): string {
        return (log.workingTimeSum / (this.calculateTotalDuration() || 1) * 100).toFixed(0);
    }

    calculateTotalDuration(): number {
        let bookingTime = 0;

        this.workLogsGrouped.forEach((x) => bookingTime += x.workingTimeSum);

        return bookingTime;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Load API data
    // -----------------------------------------------------------------------------------------------------

    getGroupedLogs(ticketId: number): void {
        this.isLoading = true;

        this.subs.sink = this.workLogHttpService.getGroupedWorkLogs(ticketId)
            .pipe(
                map((logs) => orderBy(logs, ['workingTimeSum'], ['desc'])),
                tap((logs) => this.workLogsGrouped = [...logs]),
                map((logs) => logs.map((groupedLog) => ({
                    name: groupedLog.name,
                    totalTime: groupedLog.workingTimeSum,
                    percentage: this.calculatePercentage(groupedLog)
                }))),
                finalize(() => this.isLoading = false)
            )
            .subscribe((x) => {
                this.typeEvaluations = [...x];
                this.totalSum = this.typeEvaluations.reduce((acc, current) => acc + current.totalTime, 0);
            });
    }
}
